import { useEffect, useState } from 'react';

const useCookieListener = () => {
  const [cookie, setCookie] = useState(document.cookie);

  useEffect(() => {
    const interval = setInterval(() => {
      if (document.cookie !== cookie) {
        setCookie(document.cookie);
      }
    }, 2000); // Check every 2 second

    return () => clearInterval(interval);
  }, [cookie]);

  return cookie;
}

export default useCookieListener;