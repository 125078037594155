import { Box, TablePagination } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";

import { fetchApplicationsWithFiltersAPI } from "api/siaApplication";
import { animateRouteProps } from "constants/common";
import { useT } from "translation";
import OutletHeader from "../Common/OutletHeader";
import ApplicationsFilters from "./ApplicationsFilters";
import ApplicationsTable from "./ApplicationsTable";
import { generateApplicationsData } from "./utils";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { selectedAccountSelector } from "store/selectors/selectors";

const defaultApplicationsTableData: ApplicationsTableData = {
  applications: [],
  metaData: {
    total: -1,
    offset: -1,
    per_page: -1,
    total_page: -1,
  },
};

const defaultApplicationsFilter: FilterParams = {
  page: 1,
  per_page: 5,
  start_date: dayjs().subtract(30, "day").format("YYYY-MM-DD"),
  end_date: dayjs().format("YYYY-MM-DD"),
  status: "incomplete",
};

const Applications = () => {
  const { t } = useT();
  const [page, setPage] = useState(0);
  const [hasError, setHasError] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [initialCall, setIntialCall] = useState(true);
  const [loading, setLoading] = useState(false);

  const [applicationsData, setApplicationsData] =
    useState<ApplicationsTableData>(defaultApplicationsTableData);
  const [applicationsFilters, setApplicationsFilters] = useState<FilterParams>({
    ...defaultApplicationsFilter,
  });

  const selectedAccount = useSelector(selectedAccountSelector);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onDateChange = (newValue: Dayjs, name: string) => {
    setApplicationsFilters((f) => ({
      ...f,
      [name]: newValue.format("YYYY-MM-DD"),
    }));
  };

  const onApply = (filterData: Filter[]) => {
    const obj = { ...applicationsFilters };
    filterData.forEach((f) => {
      if (f.options.every((o) => o.isSelected)) {
        if (defaultApplicationsFilter[f.id]) {
          obj[f.id] = defaultApplicationsFilter[f.id];
        } else {
          delete obj[f.id];
        }
      } else {
        obj[f.id] =
          f.options
            .filter((o) => o.isSelected)
            .map((so) => so.value)
            .join(",") ?? "";
      }
    });
    setApplicationsFilters(obj);
  };

  const onClear = (filterData: Filter[]) => {
    setApplicationsFilters({ ...defaultApplicationsFilter });
  };

  const fetchSubmissions = async (payload: FilterParams) => {
    try {
      setLoading(true);
      const response = await fetchApplicationsWithFiltersAPI(payload);
      if (response?.status === 200 && response?.data?.data?.length) {
        setApplicationsData({
          applications: generateApplicationsData(response.data.data),
          metaData: response.data.meta,
        });
      } else {
        setApplicationsData(defaultApplicationsTableData);
      }
    } catch (error) {
      setHasError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSubmissions({
      ...applicationsFilters,
      page: page + 1,
      per_page: rowsPerPage,
      agent_id: selectedAccount?.member?.agent
    });

    if (initialCall) setIntialCall(false);
  }, [rowsPerPage, page, applicationsFilters]);

  return (
    <Box
      sx={{
        backgroundColor: "white",
        borderRadius: "12px",
      }}
      {...animateRouteProps}
    >
      <OutletHeader
        title={t("APPLICATIONS_PAGE.title")}
        subTitle={t("APPLICATIONS_PAGE.desc")}
      />
      <ApplicationsFilters
        onDateChange={onDateChange}
        onApply={onApply}
        onClear={onClear}
      />
      <Box sx={{ width: "100%", mt: "20px" }}>
        <Box sx={{ width: "100%", mb: 2 }}>
          <ApplicationsTable
            loadingData={loading}
            applicationDataList={applicationsData?.applications}
            hasError={hasError}
          />
          <TablePagination
            sx={{ color: "black" }}
            labelRowsPerPage={t("TABLES.rowsPerPage")}
            labelDisplayedRows={({ from, to, count }) => {
              return `${from}–${to} ${t("TABLES.of")} ${
                count !== -1 ? count : `${t("TABLES.moreThan")} ${to}`
              }`;
            }}
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={applicationsData?.metaData?.total ?? rowsPerPage}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Applications;
