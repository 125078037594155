import { useQuery } from "@tanstack/react-query";
import { ToastCtx } from "components/Toast/ToastProvider";
import useDashboardStartScreen from "hooks/useDashboardStartScreen";
import { isEqual } from "lodash";
import { ERole, IChildAccount } from "modules/Accounts/types";
import { fetchAccountsOfUserApi } from "modules/Auth/api";
import { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  setUserActiveAccounts,
  setAllUserAccounts,
  setUserInvitedAccount,
  setUserSelectedAccount,
} from "store/features/authSlice";
import { selectedAccountSelector } from "store/selectors/selectors";
import { RootState } from "store/store";
import { useT } from "translation";
import { IListResponse } from "types";
import { LSKeys, getFromLocalStorage } from "utils/ls.utils";

function useAccountsInfo() {
  const accessToken = getFromLocalStorage(LSKeys.ACCESS_TOKEN);
  const { setToast } = useContext(ToastCtx);
  const dispatch = useDispatch();
  const { t } = useT();
  const navigate = useNavigate();
  const location = useLocation();
  const isInviteRoute = location.pathname.includes("/accept-invite");
  const [isAccountsLoading, setIsAccountsLoading] = useState(
    isInviteRoute ? false : !!accessToken
  );
  const selectedAccount = useSelector(
    selectedAccountSelector
  );
  const dashboardStartScreen = useDashboardStartScreen();
  const {
    data: accountsRes,
    isSuccess,
    isLoading,
    isFetching,
    isError,
  } = useQuery<IListResponse<IChildAccount>>({
    queryKey: ["accounts", accessToken],
    queryFn: fetchAccountsOfUserApi,
    enabled: Boolean(accessToken) && !isInviteRoute,
    refetchOnWindowFocus: false,
  });

  const accountsList = useMemo(
    () => (accountsRes?.data || []).filter(account => ["invited", "active"].includes(account.member.status)),
    [accountsRes?.data]
  );

  useEffect(() => {
    if (isLoading) {
      setIsAccountsLoading(true);
    }
  }, [isLoading]);

  useEffect(() => {
    if (!accessToken || isLoading || isFetching || isInviteRoute) return;
    if (isError) {
      setIsAccountsLoading(false);
      return setToast({
        severity: "error",
        text: t("SOMETHING_WENT_WRONG"),
      });
    }
    if (isSuccess) {
      setIsAccountsLoading(false);
      if (accountsList.length > 0) {
        const activeAccounts = accountsList.filter(
          (account) => account.member.status === "active"
        );
        const invitedAccounts = accountsList.filter(
          (account) => account.member.status === "invited"
        );
        dispatch(setAllUserAccounts(accountsList));
        dispatch(setUserActiveAccounts(activeAccounts));
        dispatch(setUserInvitedAccount(invitedAccounts));
        if (!selectedAccount) {
          const adminAcc = activeAccounts.find(acc => acc.member.roles.includes(ERole.ADMIN));
          dispatch(setUserSelectedAccount(adminAcc ?? activeAccounts[0]));
        } else {
          const updatedSelectedAcc = activeAccounts.find(
            (active) => active.id === selectedAccount.id
          );
          if (!isEqual(updatedSelectedAcc, selectedAccount))
            dispatch(setUserSelectedAccount(updatedSelectedAcc));
        }
        if (activeAccounts.length > 0 && location.pathname === "/home/onboarding") {
          navigate(dashboardStartScreen)
        }
        else if (
          activeAccounts.length === 0 && invitedAccounts.length > 0
          && location.pathname !== "/home/notifications"
        ) {
          if (location.pathname !== "/home/onboarding") {
            navigate("/home/onboarding", {
              replace: true,
              state: location,
            });
          }
        }
      } else {
        dispatch(setAllUserAccounts([]));
        dispatch(setUserActiveAccounts([]));
        dispatch(setUserInvitedAccount([]));
        dispatch(setUserSelectedAccount(null));
        if (location.pathname !== "/home/onboarding") {
          navigate("/home/onboarding", {
            replace: true,
            state: location,
          });
        }
      }
    }
  }, [
    selectedAccount,
    isInviteRoute,
    accessToken,
    isLoading,
    isFetching,
    isError,
    isSuccess,
    accountsList,
    location.pathname,
    t,
    dispatch,
    navigate,
    location,
    setIsAccountsLoading,
    setToast,
    dashboardStartScreen
  ]);
  return { isAccountsLoading };
}

export default useAccountsInfo;
