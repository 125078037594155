import React from "react";

import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import TableComponent from "components/common/TableComponent";
import ViewOption from "components/common/ViewOption";
import { useT } from "translation";
import { policyStatusMapping } from "./utils";
import { PORTAL_NAME, PortalName } from "config";

interface PoliciesTableProps {
  policiesDataList: PolicyData[];
  loadingData: boolean;
  hasError: boolean;
}

const PoliciesTable: React.FC<PoliciesTableProps> = ({
  policiesDataList,
  loadingData,
  hasError,
}) => {
  const navigate = useNavigate();

  const { t } = useT();

  const PolicyStatus: React.FC<{ policyData: PolicyData }> = ({
    policyData,
  }) => (
    <Box
      sx={{
        backgroundColor:
          policyStatusMapping[policyData.status || ""]?.backgroundColor,
        p: "5px",
        borderRadius: "100px",
        maxWidth: "110px",
      }}
    >
      <Typography
        sx={{
          fontSize: "12px",
          fontWeight: 500,
          color: policyStatusMapping[policyData.status || ""]?.color,
          whiteSpace: "nowrap",
          textAlign: "center",
        }}
      >
        {t(policyStatusMapping[policyData.status || ""]?.label)}
      </Typography>
    </Box>
  );

  const headCells: TableComponentHeadCell[] = [
    {
      id: "policy_num",
      label: t("POLICIES_PAGE.tableHeaders.policy_num"),
    },
    // {
    //   id: "policy_holder",
    //   label: t("POLICIES_PAGE.tableHeaders.policy_holder"),
    // },
    {
      id: "insurance",
      label: t("POLICIES_PAGE.tableHeaders.insurance"),
    },
    {
      id: "insurer",
      label: t("POLICIES_PAGE.tableHeaders.insurer"),
    },
    {
      id: "commission",
      label: t("POLICIES_PAGE.tableHeaders.commission"),
    },
    // {
    //   id: "renewal_date",
    //   label: t("POLICIES_PAGE.tableHeaders.renewal_date"),
    // },
    {
      id: "status",
      label: t("POLICIES_PAGE.tableHeaders.status"),
    },
    {
      id: "action",
      label: t("POLICIES_PAGE.tableHeaders.action"),
    },
  ];

  const tableRows: TableComponentRow[] = policiesDataList.map((policy) => ({
    id: policy.policy_num,
    cells: [
      {
        id: policy.policy_num,
        label: policy.policy_num,
      },
      // { id: policy.policy_holder, label: policy.policy_holder },
      { id: policy.insurance, label: policy.insurance },
      { id: policy.insurer, label: policy.insurer },
      {
        id: `${policy.policy_num ?? Math.random()}-commission`,
        label: policy.commission?.final_commission_value,
      },
      // { id: policy.renewal_date, label: policy.renewal_date },
      { id: policy.status, label: <PolicyStatus policyData={policy} /> },
      {
        id: `${policy.policy_num ?? Math.random()}-actions`,
        actions: policy.documents?.length
          ? [
              {
                title: "View",
                label: <ViewOption />,
                cb: () => {
                  const searchParams = new URLSearchParams();
                  searchParams.append(
                    "fileURL",
                    policy?.documents[(PORTAL_NAME === PortalName.FAIRFAX && policy?.documents?.length > 1) ? 1 : 0]?.download_url
                  );
                  navigate({
                    pathname: `/home/policies/${policy?.policy_num}`,
                    search: searchParams.toString(),
                  });
                },
              },
            ]
          : [],
        label: "Actions",
        type: "action",
      },
    ],
  }));

  return (
    <TableComponent
      headCells={headCells}
      loading={loadingData}
      errorMessage={hasError && t("SOMETHING_WENT_WRONG")}
      noDataMessage={t("NO_DATA_AVAILABLE")}
      rows={tableRows}
    />
  );
};
export default PoliciesTable;
