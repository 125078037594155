import { Box, CircularProgress } from "@mui/material";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { IconLogout, IconSettings, IconUser } from "@tabler/icons-react";
import { useQuery } from "@tanstack/react-query";
import PopupModal from "components/common/PopupModal/PopupModal";
import AnimatedButton from "components/custom/AnimatedButton";
import { FAIRFAX, PORTAL_NAME } from "config";
import { fetchUserProfileDetails } from "modules/Profile/api";
import { IUserDetails } from "modules/Profile/types";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  signOut,
} from "store/features/authSlice";
import { customStylesSelector, selectedAccountSelector } from "store/selectors/selectors";
import { RootState } from "store/store";
import { useT } from "translation";
import {
  LSKeys,
  deleteFromLocalStorage,
  getFromLocalStorage,
} from "utils/ls.utils";

const UserPopover = () => {
  const { t } = useT();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const customStyles = useSelector(customStylesSelector);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const selectedAccount = useSelector(
    selectedAccountSelector
  );
  const isV2Auth = getFromLocalStorage(LSKeys.ACCESS_TOKEN);

  const {
    data: userDetails,
    isLoading: isUserDetailsLoading,
    isError: isUserDetailsError,
  } = useQuery<IUserDetails>({
    queryFn: fetchUserProfileDetails.queryFn,
    queryKey: fetchUserProfileDetails.queryKey,
    enabled: Boolean(isV2Auth),
  });
  const isStereDomain = window.location.hostname.includes('stere');
  // For fairfax added redirect URI to handle logout
  // as cookies are being set from different domain we will be redirecting user back to fairfax portal to handle logout case
  const ffRedirectUri = PORTAL_NAME === FAIRFAX && !isStereDomain ? (getFromLocalStorage(LSKeys.REDIRECT_URI) || '') : '';
  // Hide logout button for fairfax if redirect uri is not present as a fallback 
  const isLogoutBtnHidden = PORTAL_NAME === FAIRFAX && !ffRedirectUri && !isStereDomain;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const redirect = () => {
    deleteFromLocalStorage(LSKeys.ACCESS_TOKEN);
    window.location.href = ffRedirectUri
  }
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSave = () => {

    dispatch(signOut());
  };

  const open = Boolean(anchorEl);
  const id = open ? "user-popover" : undefined;
  if (isUserDetailsLoading)
    return <CircularProgress />;
  if (isUserDetailsError) {
    return null;
  }
  const isAccountSettingVisible = !selectedAccount;
  return (
    <>
      <Box>
        <AnimatedButton
          aria-describedby={id}
          variant="contained"
          onClick={handleClick}
          sx={{
            minWidth: "fit-content",
            padding: "8px",
            borderRadius: "50%",
          }}
        >
          <IconUser stroke={1.5} size="1.5rem" color="white" />
        </AnimatedButton>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          sx={{
            mt: "10px",
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Box
            sx={{
              p: "20px",
              minWidth: "200px",
              display: "flex",
              flexDirection: "column",
              gap: "15px",
            }}
          >
            <Typography
              sx={{
                color: customStyles.muiTheme.palette.text.primary,
                fontSize: "18px",
                fontWeight: 600,
                ml: "8px",
              }}
            >
              {`${userDetails?.first_name || ""} ${userDetails?.last_name || ""
                }`}
            </Typography>

            {isAccountSettingVisible ? null : (
              <AnimatedButton
                sx={{ textTransform: "none", justifyContent: "flex-start" }}
                onClick={() =>
                  navigate("/home/profile")
                }
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "15px",
                  }}
                >
                  <IconSettings stroke={1.5} size="1.5rem" color="grey" />
                  <Typography
                    sx={{
                      color: "grey",
                      fontSize: "14px",
                      fontWeight: 500,
                    }}
                  >
                    {t("SETTINGS")}
                  </Typography>
                </Box>
              </AnimatedButton>
            )}
            {!isLogoutBtnHidden && <AnimatedButton
              sx={{ textTransform: "none", justifyContent: "flex-start" }}
              onClick={ffRedirectUri ? redirect : handleOpenModal}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "15px",
                }}
              >
                <IconLogout stroke={1.5} size="1.5rem" color="grey" />
                <Typography
                  sx={{
                    color: "grey",
                    fontSize: "14px",
                    fontWeight: 500,
                  }}
                >
                  {t("LOGOUT")}
                </Typography>
              </Box>
            </AnimatedButton>}
          </Box>
        </Popover>
      </Box>
      <PopupModal
        open={isModalOpen}
        title={t("POP_UPS.LOGOUT_POPUP.title")}
        content={t("POP_UPS.LOGOUT_POPUP.content")}
        btnText={t("POP_UPS.LOGOUT_POPUP.btnText")}
        onClose={handleCloseModal}
        onSave={handleSave}
      />
    </>
  );
};

export default UserPopover;
