import Cookies from "js-cookie";
import { useEffect, useState } from "react";

import { PORTAL_NAME, PortalName } from "config";
import {
  deleteFromLocalStorage,
  getFromLocalStorage,
  LSKeys,
} from "utils/ls.utils";

const useTokenListener = (data: string) => {
  const cookieData = data.split('; ')
  console.log("Cookie changed:", data.split('; '));
  const [intervalRef, setIntervalRef] = useState<NodeJS.Timer>();

  const isStereDomain = window.location.hostname.includes("stere");
  const ffRedirectUri =
    PORTAL_NAME === PortalName.FAIRFAX && !isStereDomain
      ? getFromLocalStorage(LSKeys.REDIRECT_URI) || ""
      : "";

  useEffect(() => {
    const checkToken = () => {
      const token = cookieData.find(row => row.startsWith('token='))?.split('=')?.[1];
      // const loginOrigin = cookieData.find(row => row.startsWith('login_origin=')).split('=')[1];
      if (!token) {
        onLogout();
      }
    };

    const onLogout = () => {
      deleteFromLocalStorage(LSKeys.ACCESS_TOKEN);
      window.location.href = ffRedirectUri;
    };

    if (ffRedirectUri) {
      console.log("Cookie data:", cookieData)
      setIntervalRef(setInterval(checkToken, 10000));
    }

    return () => {
      if (intervalRef) {
        clearInterval(intervalRef);
      }
    };
  }, [data]);
};

export default useTokenListener;
